import {
  CLEAR_SUBMIT_MODAL,
  HIDE_AGENT_MODAL,
  HIDE_MODAL,
  OPEN_AGENT_MODAL,
  OPEN_MODAL,
  PRODUCT_Id,
  PRODUCT_NAME,
  SUBMIT_MODAL,
  VALUE_DATA,
} from "../constants";

export const openAgentIntentModal = () => {
  // console.log(type, img);
  return {
    type: OPEN_AGENT_MODAL,
    // payload: { type, img },
  };
};

export const hideAgentIntentModal = () => {
  return {
    type: HIDE_AGENT_MODAL,
  };
};

export const openModal = (type, img) => {
  // console.log(type, img);
  return {
    type: OPEN_MODAL,
    payload: { type, img },
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};

export const clearSubmitModal = () => {
  localStorage.removeItem("amount")
  return {
    type: CLEAR_SUBMIT_MODAL,
  };
};

export const submitModal = (name, values) => (dispatch, getState) => {
  const newValues = { ...getState().modalSet.submit };
  newValues[name] = values;
  if (name === "productName") {
    dispatch({ type: PRODUCT_NAME, payload: values });
  }
  if (name == "customerId" || name == "phoneNumber") {
    localStorage.setItem("customerId", values)
  }
  if (name == "email") {
    localStorage.setItem("email", values)
  }
  // if (name == "phoneNumber") {
  //   localStorage.setItem("phoneNumber", values)
  // }
  dispatch({
    type: SUBMIT_MODAL,
    payload: newValues,
  });
};

export const valueData = (productId, slug) => (dispatch, getState) => {
  const newValues = { ...getState().modalSet.submit };
  newValues["product"] = productId;
  newValues["slug"] = slug;
  dispatch({ type: PRODUCT_Id, payload: productId });
  dispatch({
    type: VALUE_DATA,
    payload: newValues,
  });
};
