function mostViewSort(data) {
  let initial = 0;
  let secondFirst = Math.floor(data.length / 2) - 1;
  let results = [];
  data.forEach((item, i) => {
    if (i % 2) {
      secondFirst += 1;
      results.push({ ...data[secondFirst], id: secondFirst + 1 });
    } else {
      initial += 1;
      results.push({ ...data[initial], id: initial });
    }
  });
  return results;
}

function mostViewSortData(data) {
  let initial = 0;
  let secondFirst = Math.floor(data.length / 2) - 1;
  let results = [];
  console.log(data);
  data.forEach((item, i) => {
    if (i % 2) {
      secondFirst += 1;
      results.push({ ...data[item], id: secondFirst + 1 });
    } else {
      initial += 1;
      console.log(item);
      results.push(data);
    }
  });
  return results;
}

function toTwoDecimal(num) {
  let roundedNum = parseFloat(num.toFixed(2));

  return roundedNum;
}

function removeString(data) {
  return JSON.parse(data);
}

export { mostViewSort, mostViewSortData, removeString, toTwoDecimal };
