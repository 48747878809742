import { RANKING_FAIL, RANKING_LOADED, RANKING_LOADING } from "../constants";
import axios from "axios";

export const getRanking = (billeruser) => (dispatch, getState) => {
  const config = {
    headers: {
      // merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: RANKING_LOADING });
  axios
    .get(
      `${process.env.REACT_APP_API}/api/transaction/ranking/${billeruser}`,
      config
    )
    .then((res) =>
      dispatch({
        type: RANKING_LOADED,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: RANKING_FAIL,
      });
    });
};
