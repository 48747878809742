import axios from "axios";
// import { tokenConfig } from "./userAction";
import {
  PRODUCT_LOADING,
  PRODUCT_LOADED,
  PRODUCT_FAIL,
  GET_PRODUCT_FAIL,
  CLEAR_PRODUCTS,
  PRODUCTLIST,
  PRODUCTLIST_error,
  SELECT_PRODUCTS,
} from "../constants";
import { productImg } from "./productImg";

export const selectProduct = (data) => {
  return {
    type: SELECT_PRODUCTS,
    payload: data
  }
}

export const getProducts = () => (dispatch, getState) => {
  // const productImage = getState().product.productImg.data;
  const config = {
    headers: {
      // merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: PRODUCT_LOADING });
  axios
    .get(`${process.env.REACT_APP_API}/api/product/merchant`, config)
    .then((res) => {
      console.log(res.data);
      let r = res.data.map((allData) => {
        return allData.productId.logourl;
      });
      dispatch(productImg(r));
      dispatch({
        type: PRODUCT_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PRODUCT_FAIL,
      });
    });
};

export const getProductList = (values) => (dispatch) => {
  const config = {
    headers: {
      // merchantKey: "099035353",
      "Content-Type": "application/json",
    },
  };

  axios
    .post(
      `${process.env.REACT_APP_API}/api/product/p3pscreen`,
      values,
      config
    )
    .then((res) => {
      // dispatch(hideLoader());
      return dispatch({
        type: PRODUCTLIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch(hideLoader());
      // if (err.response.status === 500) {
      //   window.location.href = `/error/process`;
      // } else {
      // if (err.response) {
      //   dispatch(
      //     returnErrors(
      //       err.response.data,
      //       err.response.status,
      //       "PRODUCTLIST_error"
      //     )
      //   );
      // }
      dispatch({
        type: PRODUCTLIST_error,
      });
      // }
    });
};

export const clearProducts = () => {
  return {
    type: CLEAR_PRODUCTS,
  };
};

// export const getProducts = () => (dispatch, getState) => {
//   dispatch({ type: PRODUCT_LOADING });
//   axios
//     .get(
//       `${process.env.REACT_APP_API}/fastpayr/api/v1/serviceprovider/product/byklass/1`
//       // tokenConfig(getState)
//     )
//     .then((res) =>
//       dispatch({
//         type: PRODUCT_LOADED,
//         payload: res.data,
//       })
//     )
//     .catch((err) => {
//       if (err.response) {
//         dispatch(returnErrors(err.response.data, err.response.status));
//       }
//       dispatch({
//         type: PRODUCT_FAIL,
//       });
//     });
// };

// export const getProductsById = () => (dispatch, getState) => {
//   dispatch({ type: GET_PRODUCTBYID_LOADING });
//   axios
//     .get(
//       `${process.env.REACT_APP_API}/fastpayr/api/v1/serviceprovider/product/byklass/1`
//       // tokenConfig(getState)
//     )
//     .then((res) => dispatch({ type: GET_PRODUCT_BYID, payload: res.data }))
//     .catch((err) => {
//       if (err.response) {
//         dispatch(returnErrors(err.response.data, err.response.status));
//       }
//       dispatch({
//         type: GET_PRODUCT_FAIL,
//       });
//     });
// };
