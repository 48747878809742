import React, { useEffect, useRef, useState } from "react";
import "./receipt.css";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import html2pdf from "html2pdf.js";
import logo from "../../images/social/logo.png"
import { hideAgentIntentModal } from "../../store/actions/modalSetup";
import { removeString, toTwoDecimal } from "../../utils/commonFunctions";
import { requeryCoralTxn } from "../../store/actions/finalPayment";
import { txStatusQuery } from "../../store/actions/Coralpay";
import { WalletBalance } from "../../store/actions/wallet";

export default function Receipt() {
  const dispatch = useDispatch()
  const pdfRef = useRef();
  const history = useHistory();
  const finalData = useSelector((state) => state.finalPayment);
  const transSuccess = useSelector((state) => state.trans.success);
  const modalSet = useSelector(state => state.modalSet)
  const requeryData = useSelector((state) => state.finalPayment.requery);
  const intentData = useSelector((state) => state.trans.nonUserIntent);
  const paymentIntent = useSelector((state) => state.trans.paymentIntent);
  const walletUserInit = useSelector((state) => state.trans.walletUserInit);
  const intent = useSelector((state) => state.trans);
  const [date, setDate] = useState("");
  const [email, setEmail] = useState(null);
  const [description, setDescription] = useState(null);
  const [customerName, setCustomerName] = useState(null)
  const [packageName, setPackageName] = useState(null);
  const [paymentRef, setpaymentRef] = useState(null);
  const [productLogo, setProductLogo] = useState(null)
  const [addr, setAddr] = useState(null)
  const [amount, setAmount] = useState(null)
  const [tokenData, setTokenData] = useState(null)
  const [units, setUnits] = useState(null)
  const [narration, setNarration] = useState(null);
  const [status, setStatus] = useState(null);
  const [fee, setFee] = useState(null)
  const [totalAmount, setTotalAmount] = useState(null)
  const [typeData, setTypeData] = useState(null)
  const [ktc1, setktc1] = useState(null)
  const [blocking, setBlocking] = useState(false);
  const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query)
  const tranStatus_requery = useSelector(state => state.invokeCoralpay.txrequery)
  const [ktc2, setktc2] = useState(null)
  const wallet = useSelector(state => state.wallet)
  const agentWallet = useSelector(state => state.agentFunding)
  const [customerMessageData, setCustomerMessageData] = useState(null)

  const printToPdf = () => {
    // let src = pdfRef.current.
    const pdfOptions = {
      margin: 10,
      filename: "reload_receipt.pdf",
      image: { type: "png", quality: 0.98, src: "https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/reloadlogo.png" },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(pdfRef.current, pdfOptions);
  };

  useEffect(() => {
    if (finalData.data) {
      dispatch(hideAgentIntentModal());
      dispatch(requeryCoralTxn(finalData.data.result.txMessage.systemReference))
      dispatch(txStatusQuery(finalData.data.result.txMessage.systemReference));
      // let fd = finalData.data.result ? JSON.parse(finalData.data.result.txMessage) : null;
      // let fd = finalData.data.result && finalData.data.result.txMessage
      let fd = finalData.data.result.productResult
      let val = transSuccess ? removeString(transSuccess.data.dataObject) : "";
      console.log(val)
      //   ? JSON.parse(finalData.data.result.txMessage)
      // ? finalData.data.result.txMessage
      // : null;
      let descType = intent.success ? intent.success.account.productId.productcategoryId.categoryname : ""
      let fdata = finalData.data.result.txMessage.transactionlogId;
      let amountValues = intentData ? intentData.result.amount : intent.paymentIntent ? intent.paymentIntent.result.amount : intent.walletUserInit ? intent.walletUserInit.result ? intent.walletUserInit.result.amount : intent.walletUserInit.amount : ""
      let feeData = intentData ? intentData.result.fee : intent.paymentIntent ? intent.paymentIntent.result.fee : intent.walletUserInit ? intent.walletUserInit.result ? intent.walletUserInit.result.fee : intent.walletUserInit.fee : ""
      let sumAmount = intentData ? intentData.result.totalAmount : intent.paymentIntent ? intent.paymentIntent.result.totalAmount : intent.walletUserInit ? intent.walletUserInit.result ? intent.walletUserInit.result.totalAmount : intent.walletUserInit.totalAmount : ""
      // let address = val ? val.responseData.customer.customerAddress ? val.responseData.customer.customerAddress : val.responseData.customer.address : ""
      // let customerName = val ? val.responseData.customer.customerName : ""
      let address = val ? val.responseData.customer.customerAddress : localStorage.getItem("address") ? localStorage.getItem("address") : ""
      let customerName = val ? val.responseData.customer.customerName : localStorage.getItem("customerName") ? localStorage.getItem("customerName") : ""
      setCustomerName(customerName)
      setTypeData(descType)
      setAddr(address)
      // setAddr(val != "" ? address : "")
      setktc1(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.kct1 : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData ? fd.responseData.responseData.responseData.tokenData.kct1 : "" : "")
      setktc2(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.kct2 : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData ? fd.responseData.responseData.responseData.tokenData.kct2 : "" : "")
      // setktc1(fd ? fd.responseData.responseData.tokenData.kct1 : "")
      // setktc2(fd ? fd.responseData.responseData.tokenData.kct2 : "")
      setTotalAmount(formatAmount(sumAmount))
      setEmail(modalSet.submit.email);
      setDescription(fdata.description);
      setAmount(formatAmount(amountValues))
      setFee(formatAmount(feeData))
      setProductLogo(fdata.productId.logourl)
      setDate(fdata.transactiondate);
      setPackageName(fd.responseData.responseData.packageName ? fd.responseData.responseData.packageName : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.packageName : "");
      // setPackageName(fd.responseData.responseData.packageName);
      setpaymentRef(finalData.data.result.reference);
      setCustomerMessageData(fd.responseData.responseData ? fd.responseData.responseData.customerMessage : fd.responseData.responseData.responseData.customerMessage);
      // setNarration(fd.responseData.responseData.customerMessage);
      // setCustomerMessageData(fd.responseData.responseData.customerMessage ? fd.responseData.responseData.customerMessage : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.customerMessage : "")
      console.log(fd)
      if (fd && fd.responseData && fd.responseData.responseData && fd.responseData.responseData.responseData) {
        const { customerMessage } = fd.responseData.responseData.responseData;

        console.log(customerMessage)
        // Check if customerMessage exists before setting it
        if (customerMessage) {
          setUnits(
            fd.responseData?.responseData?.tokenData?.stdToken?.units || ""
          );

          setTokenData(
            fd.responseData?.responseData?.tokenData?.stdToken?.value || ""
          );
          // setUnits(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.stdToken.units : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData.stdToken.units ? "" : "" : "")
          // setTokenData(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.stdToken.value : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData.stdToken.value ? "" : "" : "")
          setNarration(customerMessage);
        }
      }
      setStatus(fd.responseData.responseData.status ? fd.responseData.responseData.status : fd.responseData.responseData.vendStatus);
    } else {
      window.location.href = "/"
    }
  }, [finalData]);

  useEffect(() => {
    if (requeryData && modalSet) {
      let fd = requeryData.responseData;
      let descType = intent.success ? intent.success.account.productId.productcategoryId.categoryname : requeryData.responseData.billerName
      // let fdata = finalData.data.result.productResult.transactionlogId;
      let amountValues = intentData ? intentData.result.amount : intent.paymentIntent ? intent.paymentIntent.result.amount : intent.walletUserInit ? intent.walletUserInit.result.amount : ""
      let feeData = intentData ? intentData.result.fee : intent.paymentIntent ? intent.paymentIntent.result.fee : intent.walletUserInit ? intent.walletUserInit.result.fee : ""
      let sumAmount = intentData ? intentData.result.totalAmount : intent.paymentIntent ? intent.paymentIntent.result.totalAmount : intent.walletUserInit ? intent.walletUserInit.result.totalAmount : ""
      setTypeData(descType)
      setktc1(fd.tokenData ? fd.tokenData.kct1 : "")
      setktc2(fd.tokenData ? fd.tokenData.kct2 : "")
      setTotalAmount(formatAmount(sumAmount))
      setEmail(modalSet.email);
      setDescription("Requery Successful");
      setAmount(formatAmount(amountValues))
      setFee(formatAmount(feeData))
      setProductLogo(modalSet.img)
      setDate(fd.date);
      setPackageName(fd.billerName);
      setpaymentRef(fd.paymentReference);
      setNarration(fd.customerMessage);
      setStatus(fd.status);
    }
  }, [requeryData])

  const handleBack = (e) => {
    e.preventDefault();
    localStorage.clear()
    window.location.href = `${process.env.PUBLIC_URL}/`
    // history.goBack();
  };

  function formatAmount(amount) {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  }

  useEffect(() => {
    if (finalData.data || requeryData) {
      dispatch(WalletBalance(wallet.user ? wallet.user.accountNo : agentWallet.balance ? agentWallet.balance.data.accountNumber : ""))
      const handleBeforeUnload = () => {
        setBlocking(true);
        // Optionally return a message to be displayed in the confirmation dialog
        return "Are you sure you want to leave?";
      };

      // Attach the event handler when the component mounts
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Clean up the event handler when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [finalData.data || requeryData]);

  return (
    <div>
      <div className="container" style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleBack} style={{ backgroundColor: "#FFCC28", color: "#000" }}>
          Go back
        </Button>
        <Button onClick={printToPdf}>Print to PDF</Button>
      </div>
      <section className="wrapper-invoice">
        <div className="invoice" ref={pdfRef}>
          <div className="invoice-information">
            <p>
              <b>Invoice #</b> : {tranStatus_query ? tranStatus_query.data ? tranStatus_query.data.reference : finalData ? finalData.data ? finalData.data.result.reference : "" : "" : ""}
            </p>
            <p>
              <b>Transaction Date:</b> {tranStatus_query ? tranStatus_query.data ? new Date(tranStatus_query.data.transactiondate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : tranStatus_requery ? new Date(tranStatus_requery.responseData.confirmationTime).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "" : "22"}
              {/* <b>Transaction Date:</b> {tranStatus_query ? new Date(tranStatus_query.data.transactiondate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "22"} */}
            </p>
          </div>
          {/* <!-- logo brand invoice --> */}
          <div className="invoice-logo-brand">
            <img src="https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/reloadlogo.png" width={100} alt="" />
          </div>
          {/* <!-- invoice head --> */}
          <div className="invoice-head">
            <div className="head client-info">
            </div>
            <div className="head client-data">
              <p>-</p>
              <p>Email: {email ? email : ""}</p>

              {customerMessageData ? <p>Customer message: {customerMessageData ? customerMessageData : ""}</p> : ""}
              {tranStatus_requery ? tranStatus_requery.responseData.tokenData ?
                <>
                  {addr ? <p>Address: {addr ? addr : ""}</p> : ""}
                  {customerName ? <p>Customer name: {customerName ? customerName : ""}</p> : ""}
                  <p>Token: {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.stdToken.value : "" : ""}</p>
                  <p>Unit: {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.stdToken.units : "" : ""}</p>
                </>
                : "" : ""}
              <p>Status: Transaction successful</p>
              {/* <p>Status: {tranStatus_query ? tranStatus_query.data.clientpaymentId.responseMessage : ""}</p> */}
              {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.kct1 && tranStatus_requery.responseData.tokenData.kct2 ?
                <>
                  {tranStatus_requery.responseData.tokenData.kct1 != "" && tranStatus_requery.responseData.tokenData.kct2 != "" ?
                    <>
                      <p>kct1: {tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.kct1 : ""}</p>
                      <p>kct2: {tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.kct2 : ""}</p>
                    </>
                    : ""
                  }
                </>
                : "" : "" : ""}</div>
            {/* <div>
              <p>{tranStatus_requery ? tranStatus_requery.responseData.customerMessage.replace("C'Gate", "") : ""}</p>
            </div> */}
          </div>
          {/* <!-- invoice body--> */}
          <div className="invoice-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{tranStatus_requery?.responseData?.billerName || ""}</td>
                  <td>
                    {tranStatus_query?.data?.amount ||
                      walletUserInit?.result?.amount ||
                      (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")}
                  </td>
                </tr>
                <tr>
                  <td>Convenience fee</td>
                  <td>
                    {tranStatus_query?.data?.fee ||
                      walletUserInit?.result?.fee ||
                      (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.fee) : "")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex-table">
              <div className="flex-column"></div>
              <div className="flex-column">
                <table className="table-subtotal">
                  <tbody>
                    <tr>
                      <td>Amount paid</td>
                      <td>
                        {
                          tranStatus_query && tranStatus_query.data
                            ? tranStatus_query.data.amount
                            : walletUserInit && walletUserInit.result
                              ? walletUserInit.result.amount
                              : paymentIntent && paymentIntent.result
                                ? toTwoDecimal(paymentIntent.result.totalAmount)
                                : ""
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Convenience fee</td>
                      <td>
                        {
                          tranStatus_query && tranStatus_query.data
                            ? tranStatus_query.data.fee
                            : walletUserInit && walletUserInit.result
                              ? walletUserInit.result.fee
                              : paymentIntent && paymentIntent.result
                                ? toTwoDecimal(paymentIntent.result.fee)
                                : ""
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- invoice total  --> */}
            <div className="invoice-total-amount">
              <p>
                Total: {
                  tranStatus_query?.data?.price ??
                  walletUserInit?.result?.totalAmount ??
                  (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")
                }
              </p>
              {/* <p>Total :{paymentIntent ? paymentIntent.result ? toTwoDecimal(paymentIntent.result.totalAmount) : intentData ? toTwoDecimal(intentData.totalAmount) : "" : ""}</p> */}
              {/* <p>Total :{paymentIntent ? paymentIntent.result.totalAmount : intentData ? intentData.totalAmount : totalAmount}</p> */}
            </div>
          </div>
          {/* <!-- invoice footer --> */}
          <div className="invoice-footer">
            <p>❤Thanks for your patronage</p>
          </div>
        </div>
      </section>
    </div >
  );
}
