import React, { useEffect, useRef, useState } from "react";
import "./receipt.css";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import logo from "../../images/social/logo.png"
import { hideAgentIntentModal } from "../../store/actions/modalSetup";
import { txStatusQuery } from "../../store/actions/Coralpay";
import { requeryCoralTxn } from "../../store/actions/finalPayment";
import { removeString, toTwoDecimal } from "../../utils/commonFunctions";
import { WalletBalance } from "../../store/actions/wallet";

export default function CoralReceipt() {
  const dispatch = useDispatch()
  const pdfRef = useRef();
  const { reference } = useParams()
  const history = useHistory();
  const finalData = useSelector((state) => state.finalPayment);
  const modalSet = useSelector(state => state.modalSet)
  const requeryData = useSelector((state) => state.finalPayment.requery);
  const productAccount = useSelector(state => state.invokeCoralpay.productAccount)
  const paymentIntent = useSelector((state) => state.trans.paymentIntent);
  const intentData = useSelector((state) => state.trans.nonUserIntent);
  const transSuccess = useSelector((state) => state.trans.success);
  const walletUserInit = useSelector((state) => state.trans.walletUserInit);
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState(null);
  const [packageName, setPackageName] = useState(null);
  const [paymentRef, setpaymentRef] = useState(null);
  const [productLogo, setProductLogo] = useState(null)
  const [amount, setAmount] = useState(null)
  const [addr, setAddr] = useState(null)
  const [narration, setNarration] = useState(null);
  const [status, setStatus] = useState(null);
  const [fee, setFee] = useState(null)
  const [totalAmount, setTotalAmount] = useState(null)
  const [typeData, setTypeData] = useState(null)
  const [ktc1, setktc1] = useState(null)
  const [blocking, setBlocking] = useState(false);
  const [ktc2, setktc2] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [customerMessageData, setCustomerMessageData] = useState(null)
  const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query)
  const wallet = useSelector(state => state.wallet)
  const tranStatus_requery = useSelector(state => state.invokeCoralpay.txrequery)

  useEffect(() => {
    if (transSuccess && tranStatus_requery) {
      let val = transSuccess ? removeString(transSuccess.data.dataObject) : "";

      let address = val ? val.responseData.customer.customerAddress : localStorage.getItem("address") ? localStorage.getItem("address") : ""
      let customerName = val ? val.responseData.customer.customerName : localStorage.getItem("customerName") ? localStorage.getItem("customerName") : ""

      dispatch(WalletBalance(wallet.user ? wallet.user.accountNo : ""))
      setCustomerMessageData(tranStatus_requery.responseData.customerMessage)
      setCustomerName(customerName)
      setAddr(address)
    }
  }, [transSuccess && tranStatus_requery])


  const printToPdf = () => {
    // let src = pdfRef.current.
    const pdfOptions = {
      margin: 10,
      filename: "reload_receipt.pdf",
      image: { type: "png", quality: 0.98, src: "https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/reloadlogo.png" },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(pdfRef.current, pdfOptions);
  };

  useEffect(() => {
    dispatch(requeryCoralTxn(reference))
    dispatch(txStatusQuery(reference));
  }, [])

  // useEffect(() => {
  //   if (tranStatus_query) {
  //     console.log(tranStatus_query)
  //     localStorage.setItem("ref", tranStatus_query.data.reference)
  //   } else {
  //     dispatch(requeryCoralTxn(reference))
  //     dispatch(txStatusQuery(reference));
  //   }
  // }, [tranStatus_query])

  const handleBack = (e) => {
    e.preventDefault();
    localStorage.removeItem("ref")
    window.location.href = `${process.env.PUBLIC_URL}/`
    // history.goBack();
  };

  function formatAmount(amount) {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  }

  useEffect(() => {
    if (finalData.data || requeryData) {
      const handleBeforeUnload = () => {
        setBlocking(true);
        // Optionally return a message to be displayed in the confirmation dialog
        return "Are you sure you want to leave?";
      };

      // Attach the event handler when the component mounts
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Clean up the event handler when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [finalData.data || requeryData]);

  return (
    <div>
      <div className="container" style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleBack} style={{ backgroundColor: "#FFCC28", color: "#000" }}>
          Go back
        </Button>
        <Button onClick={printToPdf}>Print to PDF</Button>
      </div>
      <section className="wrapper-invoice">
        <div className="invoice" ref={pdfRef}>
          <div className="invoice-information">
            <p>
              <b>Invoice #</b> : {tranStatus_query ? tranStatus_query.data.reference : ""}
            </p>
            <p>
              <b>Transaction Date:</b> {tranStatus_query ? new Date(tranStatus_query.data.transactiondate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : "22"}
              {/* <b>Transaction Date </b>: {date ? new Date(date) : ""} */}
            </p>
          </div>
          {/* <!-- logo brand invoice --> */}
          <div className="invoice-logo-brand">
            {/* <!-- <h2>Tampsh.</h2> --> */}
            <img src="https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/reloadlogo.png" width={100} alt="" />
          </div>
          {/* <!-- invoice head --> */}
          <div className="invoice-head">
            <div className="head client-info">
            </div>
            <div className="head client-data">
              <p>-</p>
              <p>Email: {tranStatus_query ? tranStatus_query.data.email : ""}</p>

              {customerMessageData ? <p>Customer message: {customerMessageData ? customerMessageData : ""}</p> : ""}
              {tranStatus_requery ? tranStatus_requery.responseData.tokenData ?
                <>
                  {productAccount ? <p>Address: {productAccount ? productAccount.address : ""}</p> : ""}
                  {productAccount ? <p>Customer name: {productAccount ? productAccount.accountName : ""}</p> : ""}
                  <p>Token: {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.stdToken.value : "" : ""}</p>
                  <p>Unit: {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.stdToken.units : "" : ""}</p>
                </>
                : "" : ""}
              <p>Status: {tranStatus_query ? tranStatus_query.data.clientpaymentId.responseMessage : ""}</p>
              {tranStatus_requery ? tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.kct1 && tranStatus_requery.responseData.tokenData.kct2 ?
                <>
                  {tranStatus_requery.responseData.tokenData.kct1 != "" && tranStatus_requery.responseData.tokenData.kct2 != "" ?
                    <>
                      <p>kct1: {tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.kct1 : ""}</p>
                      <p>kct2: {tranStatus_requery.responseData.tokenData ? tranStatus_requery.responseData.tokenData.kct2 : ""}</p>
                    </>
                    : ""
                  }
                </>
                : "" : "" : ""}</div>
            {/* <div>
              <p>{tranStatus_requery ? tranStatus_requery.responseData.customerMessage.replace("C'Gate", "") : ""}</p>
            </div> */}
          </div>
          {/* <!-- invoice body--> */}
          <div className="invoice-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Item Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{tranStatus_requery ? tranStatus_requery.responseData.billerName : ""}</td>
                  <td>{tranStatus_query ? tranStatus_query.data.amount : walletUserInit ? walletUserInit.result.amount : ""}</td>
                </tr>
                <tr>
                  <td>Convenience fee</td>
                  <td>{tranStatus_query ? tranStatus_query.data.fee : walletUserInit ? walletUserInit.result.fee : ""}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex-table">
              <div className="flex-column"></div>
              <div className="flex-column">
                <table className="table-subtotal">
                  <tbody>
                    <tr>
                      <td>Amount paid</td>
                      <td>{tranStatus_query ? tranStatus_query.data.amount : walletUserInit ? walletUserInit.result.amount : ""}</td>
                      {/* <p>{paymentIntent ? paymentIntent.result ? toTwoDecimal(paymentIntent.result.amount) : intentData ? toTwoDecimal(intentData.amount) : tranStatus_query ? tranStatus_query.data.amount : "" : ""}</p> */}
                      {/* <p>{paymentIntent ? paymentIntent.result.amount : intentData ? intentData.amount : ""}</p> */}
                    </tr>
                    <tr>
                      <td>Convenience fee</td>
                      <td>{tranStatus_query ? tranStatus_query.data.fee : walletUserInit ? walletUserInit.result.fee : ""}</td>
                      {/* <p>{paymentIntent ? paymentIntent.result ? toTwoDecimal(paymentIntent.result.fee) : intentData ? toTwoDecimal(intentData.fee) : tranStatus_query ? tranStatus_query.data.fee : "" : ""}</p> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- invoice total  --> */}
            <div className="invoice-total-amount">
              <p>Total : {tranStatus_query ? tranStatus_query.data.price : walletUserInit ? walletUserInit.result.totalAmount : ""}</p>
              {/* <p>Total :{paymentIntent ? paymentIntent.result ? toTwoDecimal(paymentIntent.result.totalAmount) : intentData ? toTwoDecimal(intentData.totalAmount) : tranStatus_query ? tranStatus_query.data.price : "" : ""}</p> */}
              {/* <p>Total :{tranStatus_query ? tranStatus_query.data.amount : ""}</p> */}
            </div>
          </div>
          {/* <!-- invoice footer --> */}
          <div className="invoice-footer">
            <p>❤Thanks for your patronage</p>
          </div>
        </div>
      </section>
    </div >
  );
}
