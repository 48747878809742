import { FINAL_PAYMENT, FINAL_PAYMENT_ERROR, REQUERY, REQUERY_ERROR, TX_REQUERY, TX_REQUERYERR } from "../constants";
import { secondTokenConfig } from "../constants/headers";
import { hideLoader } from "../actions"
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import { getAgent } from "./agentAuth";


export const finalPayment = (ref) => async (dispatch, getState) => {
  const body = JSON.stringify(ref);
  await axios
    .post(
      `${process.env.REACT_APP_API}/api/transaction/payment/mobile/finalize`,
      body,
      secondTokenConfig(getState),
      { timeout: 200 }
    )
    .then((res) => {
      dispatch({
        type: FINAL_PAYMENT,
        payload: res.data,
      })
      setTimeout(() => {
        dispatch(hideLoader());
      }, 3000);
    }
    )
    .catch((err) => {
      // Handle other HTTP status codes as needed
      dispatch({
        type: FINAL_PAYMENT_ERROR,
        payload: "An error occurred: " + err.response.status,
      });
    });
};

export const finalAgentPayment = (ref) => async (dispatch, getState) => {
  const body = JSON.stringify(ref);
  await axios
    .post(
      `${process.env.REACT_APP_API}/api/agentwallet/payment/mobile/finalize`,
      body,
      secondTokenConfig(getState),
      { timeout: 200 }
    )
    .then((res) => {
      dispatch(getAgent())
      dispatch({
        type: FINAL_PAYMENT,
        payload: res.data,
      })
      setTimeout(() => {
        dispatch(hideLoader());
      }, 3000);
    }
    )
    .catch((err) => {
      dispatch({
        type: FINAL_PAYMENT_ERROR,
        payload: "An error occurred: " + err.response.status,
      });
    });
};

export const requeryTxn = (ref) => async (dispatch, getState) => {
  // const body = JSON.stringify(ref);
  await axios
    .get(
      `${process.env.REACT_APP_API}/api/coralpay/requery/${ref}`,
      secondTokenConfig(getState),
      { timeout: 200 }
    )
    .then((res) => {
      toast.success("Sorry for the delay! transaction was successful")
      dispatch({
        type: REQUERY,
        payload: res.data,
      })
      setTimeout(() => {
        dispatch(hideLoader());
      }, 3000);
    }
    )
    .catch((err) => {
      if(err.response && err.response.data) {
        // console.log(err.response.data.message)
        toast.error(err.response.data.responseData.customerMessage)
      }
      // Handle other HTTP status codes as needed
      dispatch({
        type: REQUERY_ERROR,
        payload: "An error occurred: " + err.response.status,
      });
    });
};

export const requeryCoralTxn = (ref) => async (dispatch, getState) => {
  // const body = JSON.stringify(ref);
  await axios
    .get(
      `${process.env.REACT_APP_API}/api/coralpay/requery/${ref}`,
      secondTokenConfig(getState),
      { timeout: 200 }
    )
    .then((res) => {
      dispatch({
        type: TX_REQUERY,
        payload: res.data,
      })
      setTimeout(() => {
        dispatch(hideLoader());
      }, 3000);
    }
    )
    .catch((err) => {
      if(err.response && err.response.data) {
        console.log(err.response)
        toast.error(err.response.data.responseData.customerMessage)
      }
      // Handle other HTTP status codes as needed
      dispatch({
        type: TX_REQUERYERR,
        payload: "An error occurred: " + err.response.status,
      });
    });
};
