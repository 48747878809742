import {
  AGENT_TXN,
  AGENT_TXN_ERR,
  CLEAR_SUBMIT_MODAL,
  NON_USER_PAYMENT_INTENT,
  NON_USER_PAYMENT_INTENT_ERROR,
  PAYMENT_INTENT,
  PAYMENT_INTENT_ERROR,
  PRODUCTNAME_DATA,
  PRODUCTNAME_ERROR,
  SUBSCRIBERS,
  SUBSCRIBERS_ERROR,
  VERIFY_FAILED,
  VERIFY_SUCCESS,
  WALLET_FINAL_ERROR,
  WALLET_INIT_PAYMENT_ERROR,
  WALLET_PAYMENT_FINAL,
  WALLET_PAYMENT_INIT,
} from "../constants";

const initialState = {
  nonUserIntent: null,
  nonUserIntentError: null,
  success: null,
  error: null,
  productData: [],
  productId: null,
  productError: null,
  paymentIntent: null,
  paymentIntentError: null,
  walletUserInit: null,
  walletUserInitErr: null,
  walletUserPay: null,
  walletUserPayErr: null,
  agentTxn: [],
  agentTxn_err: null,
  subscriber: null,
  subscriber_error: null
};

const TransRed = (state = initialState, action) => {
  switch (action.type) {
    case NON_USER_PAYMENT_INTENT:
      return {
        ...state,
        nonUserIntent: action.payload
      }
    case NON_USER_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        nonUserIntentError: action.payload
      }
    case WALLET_PAYMENT_INIT:
      return {
        ...state,
        walletUserInit: action.payload
      }
    case WALLET_INIT_PAYMENT_ERROR:
      return {
        ...state,
        walletUserInitErr: action.payload
      }
    case WALLET_PAYMENT_FINAL:
      return {
        ...state,
        walletUserPay: action.payload
      }
    case WALLET_FINAL_ERROR:
      return {
        ...state,
        walletUserPayErr: action.payload
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case VERIFY_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case PRODUCTNAME_DATA:
      return {
        ...state,
        productData: action.payload,
      };
    case PRODUCTNAME_ERROR:
      return {
        ...state,
        productError: null,
      };
    case PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload,
      };
    case PAYMENT_INTENT_ERROR:
      return {
        ...state,
        paymentIntentError: action.payload,
      };
    case SUBSCRIBERS:
      return {
        ...state,
        subscriber: action.payload
      }
    case SUBSCRIBERS_ERROR:
      return {
        ...state,
        subscriber_error: action.payload
      }
    case CLEAR_SUBMIT_MODAL:
      return {
        ...state,
        success: null
      }
    // case USER_TXN:
    //   return {
    //     ...state,
    //     userTxn: action.payload
    //   }
    // case USER_TXN_ERR:
    //   return {

    //   }
    case AGENT_TXN:
      return {
        ...state,
        agentTxn: action.payload
      }
    case AGENT_TXN_ERR:
      return {
        ...state,
        agentTxn_err: action.payload
      }
    default:
      return state;
  }
};

export default TransRed;
