import {
  CLEAR_SUBMIT_MODAL,
  HIDE_AGENT_MODAL,
  HIDE_MODAL,
  OPEN_AGENT_MODAL,
  OPEN_MODAL,
  PRODUCT_Id,
  PRODUCT_NAME,
  SUBMIT_MODAL,
  VALUE_DATA,
} from "../constants";

const initialState = {
  open: false,
  openAgentIntent: false,
  submit: null,
  verify: false,
  img: "",
  productId: null,
  productName: null,
  email: localStorage.getItem("email"),
  phoneNumber: localStorage.getItem("phoneNumber")
};

const modalSetRed = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
        verify:
          action.payload.type === "Electricity" ||
          action.payload.type === "Cable TV"
            ? true
            : false,
        img: action.payload.img,
      };
    case HIDE_MODAL:
      return {
        ...state,
        open: false,
        verify: false,
      };
    case OPEN_AGENT_MODAL:
      return {
        ...state,
        openAgentIntent: true
      }
    case HIDE_AGENT_MODAL:
      return {
        ...state,
        openAgentIntent: false
      }
    case SUBMIT_MODAL:
      return {
        ...state,
        submit: action.payload,
      };
    case PRODUCT_Id:
      return {
        ...state,
        productId: action.payload,
      };
    case PRODUCT_NAME:
      return {
        ...state,
        productName: action.payload,
      };
    case CLEAR_SUBMIT_MODAL:
      return {
        ...state,
        submit: null,
        verify: false
      }
    case VALUE_DATA:
      return {
        ...state,
        submit: action.payload,
      };
    default:
      return state;
  }
};

export default modalSetRed;
